import { event } from "@/plugins/firebase/firebasePluginConfig"

/*
  type para aceitar function
  data:() => void = () => data
*/
export function error (message: string, error: unknown): void {
  event(message, {
    error,
  })
  console.error(message, error)
}
