import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // https://color.adobe.com/Posjetiti-color-theme-d5c096a2-99ec-4f2b-aa6f-a56440b12bbf/
        primary: "#D3BFE8",
        secondary: "#6600CC",
        accent: "#884ED9",
        button: "#A179D9",
        black: "#0D0D0D",
      },
    },
    icons: {
      iconfont: "md",
    },
  }
});
