import Vue from "vue";
import router from "@/plugins/router/routerPluginConfig";
import store from "@/plugins/store/storePluginConfig";
import vuetify from "@/plugins/vuetify/vuetifyPluginConfig";
import App from "@/App.vue";

import "@/plugins/firebase/firebasePluginConfig";
import "@/registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
