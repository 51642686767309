import Vue from "vue"
import { NavigationGuardNext, RouteConfig, RouteMeta } from "vue-router"
import { event } from "@/plugins/firebase/firebasePluginConfig"
import { error as catchError } from "@/helpers/treatmentErrorEventCodes"

export default [
  {
    path: "/",
    component: (): Promise<typeof import("*.vue")> => import(
      /* webpackChunkName: "home-route" */
      /* webpackPrefetch: true */
      "@/routes/routeHome.vue"
    ),
    children: [
      {
        path: "",
        name: "home",
        beforeEnter: (to: RouteMeta, _from: RouteConfig, next: NavigationGuardNext) => {
          try {
            const {
              query,
              meta,
            } = to || {}
            let url = String(query.url || "")
            if (url) {
              url = url.replace(/(web(\+| )pojestiti):\/\//g, "")
              for (const [ key, value ] of Object.entries(query)) {
                if (key !== "url" && value) url = `${url}&${key}=${value}`
              }
              Vue.set(meta, "url", url)

              event("protocol_handlers", {
                url,
              })
            }
          } catch (error) {
            catchError("error on beforeEnter", error)
          } finally {
            next()
          }
        },
        components: {
          default: (): Promise<typeof import("*.vue")> => import(
            /* webpackChunkName: "home-view" */
            /* webpackPrefetch: true */
            /* webpackFetchPriority: "high" */
            "@/views/viewHome.vue"
          ),
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
]
