import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import routesConfigDocuments from "@/plugins/router/documents/routesConfigDocuments"

Vue.use(VueRouter);

const {
  BASE_URL,
} = process.env

const routes: Array<RouteConfig> = [].concat(
  routesConfigDocuments as never[],
)

const router = new VueRouter({
  mode: "history",
  base: BASE_URL,
  routes,
});

export default router;
