import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  GoogleAuthProvider,
  User,
  linkWithPopup,
  signInWithPopup,
  signInWithCredential,
  OAuthCredential,
  OAuthProvider,
  linkWithCredential,
  deleteUser,
  signOut,
} from "firebase/auth";
import { v4 as uuid } from "uuid";
import { createuUuid } from "@/helpers/helperCreateUuid";

const {
  VUE_APP_APIKEY,
  VUE_APP_AUTHDOMAIN,
  VUE_APP_PROJECTID,
  VUE_APP_STORAGEBUCKET,
  VUE_APP_MESSAGINGSENDERID,
  VUE_APP_APPID,
  VUE_APP_MEASUREMENTID,
  VUE_APP_RECAPTCHA_V3,
} = process.env

const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: VUE_APP_APIKEY,
  authDomain: VUE_APP_AUTHDOMAIN,
  projectId: VUE_APP_PROJECTID,
  storageBucket: VUE_APP_STORAGEBUCKET,
  messagingSenderId: VUE_APP_MESSAGINGSENDERID,
  appId: VUE_APP_APPID,
  measurementId: VUE_APP_MEASUREMENTID,
}

const FIREBASE_APP = initializeApp(FIREBASE_CONFIG);
const FIREBASE_AUTH = getAuth(FIREBASE_APP as FirebaseApp)

initializeAppCheck(FIREBASE_APP, {
  provider: new ReCaptchaV3Provider(VUE_APP_RECAPTCHA_V3),
  isTokenAutoRefreshEnabled: true,
})

export function event (event: string, params?: object): void {
  logEvent(getAnalytics(FIREBASE_APP as FirebaseApp), event, params || {});
}

event("app_start");

const STATIC_AUTH = (() => {
  const AUTH = {}
  function set (key: string, value: unknown) {
    if (typeof AUTH[key] === "undefined") {
      AUTH[key] = value
    }
  }

  function get (key: string) {
    if (typeof AUTH[key] !== "undefined") {
      return AUTH[key]
    } else return {}
  }

  function del (key: string) {
    if (typeof AUTH[key] !== "undefined") {
      delete AUTH[key]
    }
  }

  /* https://stackoverflow.com/a/5423878/2741415
  STATIC_AUTH.set("key", value)
  STATIC_AUTH.get("key")
  STATIC_AUTH.del("key")
  */
  return {
    set,
    get,
    del,
  }
})()

export function currentUser (): User {
  const {
    currentUser,
  } = FIREBASE_AUTH || {}

  return currentUser as User
}

export async function signInAnonimousUser (): Promise<User> {
  if (!currentUser()) await signInAnonymously(FIREBASE_AUTH);

  STATIC_AUTH.set("ANONYMOUS_AUTH_USER", FIREBASE_AUTH)

  return currentUser()
}

export function signInUser (): void {
  const {
    isAnonymous,
  } = currentUser() || {}

  if (isAnonymous) {
    // https://firebase.google.com/docs/auth/web/account-linking?hl=pt-br
    let {
      currentUser: anonymousCurrentUser,
    } = STATIC_AUTH.get("ANONYMOUS_AUTH_USER")

    if (!anonymousCurrentUser) {
      STATIC_AUTH.set("ANONYMOUS_AUTH_USER", FIREBASE_AUTH)
      anonymousCurrentUser  = (STATIC_AUTH.get("ANONYMOUS_AUTH_USER") || {}).currentUser
    }

    // https://stackoverflow.com/a/77804077/2741415
    linkWithPopup(anonymousCurrentUser, new GoogleAuthProvider())
      .catch((linkWithPopupError) => {
        /** Isso não funciona caso o usuário já exista **/
        linkWithCredential(anonymousCurrentUser, OAuthProvider.credentialFromError(linkWithPopupError) as OAuthCredential)
          .catch(() => {
            /** preciso criar uma segunda instancia pois esta está criando outro user  anonimo **/
            // https://firebase.google.com/docs/auth/web/manage-users?hl=pt-br#web-modular-api_11
            /** Funcionou, preciso criar uma nova verificação de UID para não apagar a conta permanente **/
            STATIC_AUTH.set("AUTH_USER", FIREBASE_AUTH)
            deleteUser(anonymousCurrentUser)
            signInWithCredential(STATIC_AUTH.get("AUTH_USER"), OAuthProvider.credentialFromError(linkWithPopupError) as OAuthCredential)
          })
      })
  }

  if (!isAnonymous) {
      signInWithPopup(FIREBASE_AUTH, new GoogleAuthProvider())
        .catch(console.error)
  }
}

export function signOutUser (): void {
  signOut(FIREBASE_AUTH)
}

onAuthStateChanged(FIREBASE_AUTH, (/* user */) => {
  // if (!user) /** a ser definido, não pode criar conta anonima aqui **/
});

async function getDatabaseUuid (uuid: string): Promise<boolean> {
  uuid = String(uuid || "").trim()

  const db = getFirestore(FIREBASE_APP)
  const DOC_REF = doc(db, "shrink", uuid)
  const DOC_SNAP = await getDoc(DOC_REF)

  return DOC_SNAP.exists()
}

export async function shrink (data: Record<string, string | number | boolean>): Promise<string> {
  let {
    uid,
    url,
  } = data || {}

  const {
    path,
  } = data || {}

  const _id = String(path || createuUuid(uuid()))
  uid = String(uid || "")
  url = String(url || "")

  const db = getFirestore(FIREBASE_APP)
  const exists = await getDatabaseUuid(_id)

  if (exists) {
    event("duplicated_uuid", { _id })

    return shrink({
      url,
      uid,
      path,
    })
    /*
    setDoc(doc(db, `shrink/${_id}/updates`, createuUuid(uuid())), {
      uid,
    })
    */
  } else {
    await setDoc(doc(db, "shrink", _id), {
      uid,
      url,
    })
  }

  return _id
}
